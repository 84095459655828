#auth_page {
  height: 100vh;
  margin: 0;
  display: grid;
  place-items: center;
  background-color: #d9d9d9;
  .auth_container {
      text-align: center;
      .auth_form{
        background-color: #fff;
    }
    .deactive_tab {
      background-color: #d1e4f3;
      text-align: center;
      cursor: pointer;
    }
    .auth_btn {
      background-color: transparent;
      border: 0px;
      outline: 0px;
      font-size: larger;
      font-weight: 600;
      color: black;
    }
    .auth_body {
      display: grid;
      label {
          text-align: left;
          margin-left: 5px;
          color: #2f6994;
          display: contents;
          b{
              margin-left: 5px;
          }
          ::placeholder{
              color: red;
          }
      }
      input {
        margin: 5px;
        width: 250px;
        padding: 10px;
        
      }
      ::placeholder{
        color: #9abbd3;
    }
      button {
        margin: 5px;
        padding: 10px;
        background-color: #226aa5;
        font-weight: 600;
        border-radius: 0px;
        outline: 0px;
      }
      h6{
          border-bottom: 2px solid #d5d5d5;
          padding: 5px 0px 20px 0px;
          font-size:small;
      }
      .guest_btn {
        background-color: #6ed881;
        border-color: #6ed881;
      }
    }
  }
}
